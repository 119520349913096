import * as React from 'react';
import classNames from 'classnames';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { PageBackgroundProps } from '../PageBackground.types';
import styles from './style/PageBackground.scss';

const PageBackground: React.FC<PageBackgroundProps> = props => {
  const { id, fillLayers, isMediaPositionOverride } = props;

  return (
    <div
      id={id}
      data-media-position-override={isMediaPositionOverride}
      className={classNames(styles.pageBackground, {
        [styles.mediaPositionOverride]: isMediaPositionOverride,
      })}
    >
      <FillLayers {...fillLayers} />
    </div>
  );
};

export default PageBackground;
